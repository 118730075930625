.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px;
  background-color: #f5f5f5;
  border-radius: 5px;

  /* align-items: center; */
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  border-radius: 20px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-radius: 20px;
}

.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #fff;
  pointer-events: none;
}

.ant-picker-cell-disabled:before {
  background-color: rgba(0, 0, 0, 0);
}

.ant-picker-cell-in-view {
  color: #1bcdb7;
}

.ant-picker-cell-in-view:disabled {
  /* color: red; */
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: #f5f5f5;
  border-radius: 20px;
}

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 20px;
}
.ant-modal-footer {
  border-top: 0px;
}
