.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.small-image {
  height: 30px;
}

.parentTable
  table
  tbody
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  > td {
  padding: 0px !important;
}

.nestedTable > div > div > div {
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color: rgb(250, 250, 250);
}

/* .ant-menu-item-selected {
  background-color: #53aca0 !important;
} */

.content {
  padding: 5px 20px 5px 40px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-layout-sider .ant-layout-sider-dark {
  min-width: 210px !important;
  max-width: 220px !important;
}
.ant-select-multiple .ant-select-selection-item-content {
  white-space: normal;
}
.ant-select-multiple .ant-select-selection-item {
  height: auto;
  /* align-items: center; */
}
.ant-popover-arrow {
  display: none;
}
svg {
  vertical-align: baseline !important;
}
