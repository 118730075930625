.DatePicker {
  background: #f5f5f5;
  border-radius: 10px;
  // margin-top: 10px;
  // margin-bottom: 20px;
  height: 40px;
  display: flex;
}

.input {
  background: #f5f5f5;
  border-radius: 10px;
  // margin-top: 10px;
  // margin-bottom: 20px;
  height: 40px;
  // @media screen and (width< 400px) {
  //   margin-bottom: 15px;
  // }
  // @media screen and (width > 400px) and (width < 750px) {
  //   margin-bottom: 15px;
  // }
  // @media screen and (width > 750px) and (width < 1200px) {
  //   margin-bottom: 20px;
  // }
  // @media screen and (width > 1200px) {
  //   // width: 250px;
  //   margin-bottom: 20px;
  // }
}

.dropDown {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  border-radius: 10px;
  // margin-top: 10px;
}
.saveBtn {
  border-radius: 40px;
  text-align: center;
  margin-top: 20px;
  background-color: #1bcdb7;
  color: #fff;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.03em;
  @media screen and (width< 400px) {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 130px;
    font-size: 18px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 130px;
    font-size: 18px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 130px;
    font-size: 18px;
  }
  @media screen and (width > 1200px) {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 130px;
    font-size: 18px;
  }
}
