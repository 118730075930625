.mainContainer {
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-block-start: 20px;
  display: flex;
  flex-direction: column;
  min-height: 842px;
  // height: 842px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.h2 {
  font-weight: bold;
  letter-spacing: 3px;
}
.h5 {
  font-weight: bold;
  letter-spacing: 1px;
}
.medium {
  font-weight: 300;
  color: black;
  font-size: 16px;
  letter-spacing: 1px;
}
.left {
  width: 55%;
  height: 40px;
  align-items: center !important;
  display: flex !important;
}
.right {
  width: 15%;
  height: 40px;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  text-align: center;
}
.table {
  font-weight: "bold";
  letter-spacing: 2px;
  // color: rgb(100, 100, 100);
}
.due {
  font-weight: bold;
  letter-spacing: 2px;
}
.download {
  position: absolute;
  bottom: 20px;
  right: 6%;
  background-color: #1bcdb7;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}
