.mainContainer {
  min-height: 82vh;
}
.leftCol {
  background-color: #1bcdb7;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  @media screen and (width< 400px) {
    padding-top: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 25px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    padding-top: 35px;
    // height: 82vh;
  }
  @media screen and (width > 1200px) {
    padding-top: 10px;
    // height: 82vh;
  }
}

.rightCol {
  min-height: 82vh;
  @media screen and (width< 400px) {
    padding: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 25px;
    height: 10vh;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    padding: 35px;
  }
  @media screen and (width > 1200px) {
    padding: 15px 20px;
  }
}

.img {
  @media screen and (width< 400px) {
    width: 200px;
    margin-bottom: 35px;
    margin-top: 15px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 200px;
    margin-bottom: 35px;
    margin-top: 15px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 200px;
    margin-bottom: 40px;
    margin-top: 15px;
  }
  @media screen and (width > 1200px) {
    width: 230px;
    margin-bottom: 40px;
    margin-top: 15px;
  }
}

.therpistName {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  @media screen and (width< 400px) {
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin-bottom: 8px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 18;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin-bottom: 8px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.02em;
    margin-bottom: 8px;
  }
  @media screen and (width > 1200px) {
    font-size: 26px;
    line-height: 38px;
    letter-spacing: 0.02em;
    margin-bottom: 10px;
  }
}
.therpistDesignation {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  @media screen and (width< 400px) {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    margin-bottom: 12px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    margin-bottom: 12px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 13px;
  }
  @media screen and (width > 1200px) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
  }
}
.therpistGender {
  font-family: "Archivo";
  font-style: italic;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  @media screen and (width< 400px) {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.03em;
    margin-bottom: 30px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.03em;
    margin-bottom: 30px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.05em;
    margin-bottom: 35px;
  }
  @media screen and (width > 1200px) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-bottom: 40px;
  }
}

.greenCard {
  background: #bdf6e4;
  border-radius: 5px;

  @media screen and (width< 400px) {
    margin-bottom: 12px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    margin-bottom: 13px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    margin-bottom: 14px;
  }
  @media screen and (width > 1200px) {
    margin-bottom: 15px;
  }
}

.greenCardLargeText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  color: #000000;
  text-align: left;
  text-transform: uppercase;
  @media screen and (width< 400px) {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }
  @media screen and (width > 1200px) {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }
}

.greenCardSmallText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  color: #585758;
  text-align: left;
  @media screen and (width< 400px) {
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.03em;
    margin-top: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 25px;
    line-height: 23px;
    letter-spacing: 0.03em;
    margin-top: 16px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.03em;
    margin-top: 18px;
  }
  @media screen and (width > 1200px) {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.03em;
    margin-top: 20px;
  }
}

.rightIcon {
  color: #000000;
  display: flex;
  justify-content: center;
  font-size: large;
}

.selectedTimeSelector {
  background: #1bcdb7;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
  @media screen and (width< 400px) {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.03em;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.03em;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.03em;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media screen and (width > 1200px) {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.03em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.unSelectedTimeSelector {
  background: #f5f5f5;
  border-radius: 10px;
  color: #000000;
  text-align: center;
  margin-top: 10px;

  margin-top: 10px;
  @media screen and (width< 400px) {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.03em;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.03em;
    padding-top: 6px;
    padding-bottom: 8px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.03em;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media screen and (width > 1200px) {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.03em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.nextBtn {
  background: #1bcdb7;
  border-radius: 35px;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  // position: absolute;
  cursor: pointer;
  @media screen and (width< 400px) {
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.03em;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.03em;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 19px;
    line-height: 27px;
    letter-spacing: 0.03em;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (width > 1200px) {
    font-size: 19px;
    line-height: 27px;
    letter-spacing: 0.03em;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.selectedMonthText {
  // font-family: "Archivo";
  font-style: normal;
  font-weight: 600;

  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #000000;

  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 15px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 16px;
  }
  @media screen and (width > 1200px) {
    font-size: 18px;
  }
}
.cardView {
  border-radius: 8px;
  // box-shadow: 2px 2px 10px 0px ;
  box-shadow: 1px 2px 1px 1px rgb(215 215 215 / 73%);
  margin-bottom: 20px;
}
.cardInside {
  display: flex;
  justify-content: center;
}
.text600 {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  letter-spacing: 0.03em;
  color: #000000;
}
.cardInsideCh {
  display: flex;
  flex-direction: column;
  border-color: transparent;
  // justify-content: center;
}
.cardButton {
  background-color: #1bcdb7;
  border-radius: 28px;
  border-color: transparent;
  color: #fff;
  padding: 2px 8px 2px 8px;
  font-size: 10px;
}
.textGray {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.03em;
  color: gray;
}
