@import url('https://fonts.googleapis.com/css?family=poppins');

.spin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.5);
}

.title {
  font-family: "poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #000000;
  padding-bottom: 20px;
}

.subTitle {
  font-family: "poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px !important;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.05em;

  /* DiveThru Colours */

  color: #1bcdb7;
  // padding-bottom: 0px;
  padding-top: 15px;
}
.text {
  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.05em;

  color: #000000;
  padding-top: 20px;
}
.label {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
}

.requiredFieldRight {
  :global {
    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      ):before {
      display: none;
    }
    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      ):after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 20px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
}

.nameInputStyle {
  background: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  height: 55px;

  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}
.itemText {
  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
  padding-top: 10px;
}

.submitBtn {
  margin-top: 40px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  // align-items: center;
  // border-radius: 30px;
  background-color: #1bcdb7;
  width: 200px;
  height: 100px;

  border: none;
  cursor: pointer;
  &:hover {
    background-color: #1ce5cc;
  }
  &:active {
    background-color: #1bcdb7;
  }
  &:focus {
    background-color: #1bcdb7;
  }
}

.submit {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;

  padding-top: 2px;
  font-size: 23px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.05em;

  /* DiveThru Colours */

  color: #ffffff;
}

.DatePicker {
  border-radius: 10px;
  height: 55px;
  width: 100%;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  :global {
    .ant-picker-input > input {
      font-size: 20px !important;
    }
  }
}

.radioButton {
  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
}
.sigPad {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  position: relative;
}
.clear {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #1bcdb7;
  padding: 5px 20px;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
}
.checkboxText {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
}
